/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as t } from "../../chunks/tslib.es6.js";
import i from "../../core/Accessor.js";
import { IdentifiableMixin as o } from "../../core/Identifiable.js";
import { property as s } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as e } from "../../core/accessorSupport/decorators/subclass.js";
var r;
let c = r = class extends o(i) {
  constructor(t) {
    super(t), this.active = !1, this.className = null, this.disabled = !1, this.icon = null, this.id = null, this.indicator = !1, this.title = null, this.type = null, this.visible = !0;
  }
  clone() {
    return new r({
      active: this.active,
      className: this.className,
      disabled: this.disabled,
      icon: this.icon,
      id: this.id,
      indicator: this.indicator,
      title: this.title,
      visible: this.visible
    });
  }
};
t([s()], c.prototype, "active", void 0), t([s()], c.prototype, "className", void 0), t([s()], c.prototype, "disabled", void 0), t([s()], c.prototype, "icon", void 0), t([s()], c.prototype, "id", void 0), t([s()], c.prototype, "indicator", void 0), t([s()], c.prototype, "title", void 0), t([s()], c.prototype, "type", void 0), t([s()], c.prototype, "visible", void 0), c = r = t([e("esri.support.actions.ActionBase")], c);
const p = c;
export { p as default };