/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as s } from "../../chunks/tslib.es6.js";
import { property as t } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as i } from "../../core/accessorSupport/decorators/subclass.js";
import o from "./ActionBase.js";
var e;
let r = e = class extends o {
  constructor(s) {
    super(s), this.image = null, this.type = "button";
  }
  clone() {
    return new e({
      active: this.active,
      className: this.className,
      disabled: this.disabled,
      icon: this.icon,
      id: this.id,
      indicator: this.indicator,
      title: this.title,
      visible: this.visible,
      image: this.image
    });
  }
};
s([t()], r.prototype, "image", void 0), r = e = s([i("esri.support.actions.ActionButton")], r);
const c = r;
export { c as default };